import React, { useContext, useState } from "react";
import { UserContext } from "controller/context/userContext";
import { useNavigate } from "react-router-dom";
import { useAllOrdersList } from "model/queryCalls/queryCalls";
import { Box, Skeleton } from "@mui/material";
import Card from "components/card";
import { BsDatabaseFillX } from "react-icons/bs";
import { MdVisibility, MdChevronLeft, MdChevronRight } from "react-icons/md";
import { FiPackage } from "react-icons/fi";

// Interfaces
interface Order {
  _id: string;
  refNumber: string;
  restaurantName: string;
  orderType: string;
  amount: number;
  status: OrderStatus;
  paymentStatus: PaymentStatus;
}

type OrderStatus = "pending" | "completed" | "cancelled" | "Order Placed";
type PaymentStatus = "Paid" | "Unpaid" | "Refunded";

interface OrdersResponse {
  data: Order[];
  total: number;
  pages: number;
}

interface EmptyStateProps {
  message: string;
}

// Component Definitions
const LoadingSkeleton: React.FC = () => (
  <Box className="space-y-4">
    {[...Array(5)].map((_, i) => (
      <Skeleton key={i} height={60} />
    ))}
  </Box>
);

const EmptyState: React.FC<EmptyStateProps> = ({ message }) => (
  <div className="flex flex-col items-center justify-center py-12">
    <BsDatabaseFillX className="h-12 w-12 text-gray-400" />
    <h3 className="mt-4 text-lg font-semibold text-gray-900">{message}</h3>
    <p className="mt-1 text-sm text-gray-500">
      No orders to display at the moment.
    </p>
  </div>
);

const ITEMS_PER_PAGE = 10;

const Orders: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { userType } = useContext(UserContext);
  const navigate = useNavigate();

  const { data, isLoading, isError } = useAllOrdersList() as {
    data: OrdersResponse | undefined;
    isLoading: boolean;
    isError: boolean;
  };

  const handleViewOrder = (orderId: string): void => {
    navigate(`/${userType}/order-details/${orderId}`);
  };

  // Pagination logic
  const totalOrders = data?.data?.length || 0;
  const totalPages = Math.ceil(totalOrders / ITEMS_PER_PAGE);

  const paginatedOrders = data?.data?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const PaginationButton = ({ onClick, disabled, children }: any) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        inline-flex items-center rounded-md px-4 py-2 text-sm font-medium
        ${
          disabled
            ? "cursor-not-allowed bg-gray-100 text-gray-400"
            : "border border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
        }
      `}
    >
      {children}
    </button>
  );

  return (
    <div className="mt-6 w-full">
      <Card extra="w-full p-4 sm:p-6">
        <div className="mb-8 flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold text-navy-700 dark:text-white">
              Orders
            </h2>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
              Manage and track all your orders in one place
            </p>
          </div>
          <div className="flex items-center gap-2">
            {/* Add any action buttons here if needed */}
          </div>
        </div>

        <div className="relative">
          {isLoading ? (
            <LoadingSkeleton />
          ) : isError ? (
            <EmptyState message="Failed to fetch orders" />
          ) : data?.data?.length > 0 ? (
            <>
              <div className="overflow-x-auto rounded-xl border border-gray-200 bg-white shadow-sm dark:border-navy-700 dark:bg-navy-800">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-navy-700">
                  <thead className="bg-gray-100 dark:bg-navy-900">
                    <tr>
                      <th className="px-6 py-4 text-left">
                        <span className="text-xs font-semibold uppercase tracking-wide text-navy-700 dark:text-white">
                          Order Ref
                        </span>
                      </th>
                      {userType !== "branch" && (
                        <th className="px-6 py-4 text-left">
                          <span className="text-xs font-semibold uppercase tracking-wide text-navy-700 dark:text-white">
                            Restaurant
                          </span>
                        </th>
                      )}
                      <th className="px-6 py-4 text-left">
                        <span className="text-xs font-semibold uppercase tracking-wide text-navy-700 dark:text-white">
                          Type
                        </span>
                      </th>
                      <th className="px-6 py-4 text-left">
                        <span className="text-xs font-semibold uppercase tracking-wide text-navy-700 dark:text-white">
                          Amount
                        </span>
                      </th>
                      <th className="px-6 py-4 text-left">
                        <span className="text-xs font-semibold uppercase tracking-wide text-navy-700 dark:text-white">
                          Status
                        </span>
                      </th>
                      <th className="px-6 py-4 text-left">
                        <span className="text-xs font-semibold uppercase tracking-wide text-navy-700 dark:text-white">
                          Payment
                        </span>
                      </th>
                      <th className="px-6 py-4 text-center">
                        <span className="text-xs font-semibold uppercase tracking-wide text-navy-700 dark:text-white">
                          Actions
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-navy-700">
                    {paginatedOrders?.map((order: Order) => (
                      <tr
                        key={order._id}
                        className="transition-colors hover:bg-gray-50/60 dark:hover:bg-navy-700/50"
                      >
                        <td className="whitespace-nowrap px-6 py-4">
                          <div className="flex items-center gap-3">
                            <div className="rounded-full bg-indigo-100 p-2.5 dark:bg-indigo-900/30">
                              <FiPackage className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
                            </div>
                            <span className="font-semibold text-navy-900 dark:text-white">
                              {order?.refNumber}
                            </span>
                          </div>
                        </td>
                        {userType !== "branch" && (
                          <td className="whitespace-nowrap px-6 py-4">
                            <span className="text-sm font-medium text-gray-700 dark:text-navy-100">
                              {order.restaurantName || "Unknown"}
                            </span>
                          </td>
                        )}
                        <td className="whitespace-nowrap px-6 py-4">
                          <span className="text-sm font-medium text-gray-700 dark:text-navy-100">
                            {order.orderType || "Unknown"}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <span className="text-sm font-semibold text-gray-900 dark:text-white">
                            ₹{order.amount.toLocaleString()}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <span
                            className={`inline-flex items-center gap-1.5 rounded-full px-3 py-1.5 text-xs font-medium ${getStatusStyles(
                              order.status
                            )}`}
                          >
                            <span
                              className={`h-2 w-2 rounded-full ${getStatusDotColor(
                                order.status
                              )}`}
                            />
                            {order.status}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <span
                            className={`inline-flex rounded-full px-3 py-1.5 text-xs font-medium ${getPaymentStyles(
                              order.paymentStatus
                            )}`}
                          >
                            {order.paymentStatus}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-center">
                          <button
                            onClick={() => handleViewOrder(order._id)}
                            className="inline-flex items-center justify-center gap-2 rounded-lg bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-700 transition-all duration-200 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-900/20 dark:text-indigo-300 dark:hover:bg-indigo-900/30"
                          >
                            <MdVisibility className="h-4 w-4" />
                            <span>View</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination Controls */}
              <div className="mt-4 flex items-center justify-between px-4 py-3 sm:px-6">
                <div className="flex flex-1 justify-between sm:hidden">
                  <PaginationButton
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                  >
                    <MdChevronLeft className="h-5 w-5" />
                  </PaginationButton>
                  <PaginationButton
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                  >
                    <MdChevronRight className="h-5 w-5" />
                  </PaginationButton>
                </div>

                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">
                        {(currentPage - 1) * ITEMS_PER_PAGE + 1}
                      </span>{" "}
                      -{" "}
                      <span className="font-medium">
                        {Math.min(currentPage * ITEMS_PER_PAGE, totalOrders)}
                      </span>{" "}
                      of <span className="font-medium">{totalOrders}</span>{" "}
                      results
                    </p>
                  </div>

                  <div className="flex gap-2">
                    <PaginationButton
                      onClick={() => setCurrentPage(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </PaginationButton>

                    <PaginationButton
                      onClick={() => setCurrentPage((prev) => prev - 1)}
                      disabled={currentPage === 1}
                    >
                      <MdChevronLeft className="h-5 w-5" />
                    </PaginationButton>

                    <span className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                      {currentPage} / {totalPages}
                    </span>

                    <PaginationButton
                      onClick={() => setCurrentPage((prev) => prev + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <MdChevronRight className="h-5 w-5" />
                    </PaginationButton>

                    <PaginationButton
                      onClick={() => setCurrentPage(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </PaginationButton>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <EmptyState message="No orders found" />
          )}
        </div>
      </Card>
    </div>
  );
};

const getStatusStyles = (status: OrderStatus): string => {
  const styles: Record<OrderStatus, string> = {
    pending:
      "bg-amber-100 text-amber-900 dark:bg-amber-400/20 dark:text-amber-300",
    completed:
      "bg-gray-100 text-emerald-900 dark:bg-emerald-400/20 dark:text-emerald-300",
    cancelled:
      "bg-red-100 text-rose-900 dark:bg-rose-400/20 dark:text-rose-300",
    "Order Placed":
      "bg-green-100 text-indigo-900 dark:bg-indigo-400/20 dark:text-indigo-300",
  };
  return (
    styles[status] ||
    "bg-gray-100 text-gray-900 dark:bg-gray-600/20 dark:text-gray-300"
  );
};

const getStatusDotColor = (status: OrderStatus): string => {
  const styles: Record<OrderStatus, string> = {
    pending: "bg-amber-500",
    completed: "bg-gray-500",
    cancelled: "bg-red-500",
    "Order Placed": "bg-green-500",
  };
  return styles[status] || "bg-gray-500";
};

const getPaymentStyles = (status: PaymentStatus): string => {
  const styles: Record<PaymentStatus, string> = {
    Paid: "bg-emerald-100 text-emerald-900 dark:bg-emerald-400/20 dark:text-emerald-300",
    Unpaid: "bg-rose-100 text-rose-900 dark:bg-rose-400/20 dark:text-rose-300",
    Refunded:
      "bg-violet-100 text-violet-900 dark:bg-violet-400/20 dark:text-violet-300",
  };
  return (
    styles[status] ||
    "bg-gray-100 text-gray-900 dark:bg-gray-600/20 dark:text-gray-300"
  );
};

export default Orders;
