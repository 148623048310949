import React from "react";
import { useParams } from "react-router-dom";
import {
  FaBox,
  FaMapMarkerAlt,
  FaShoppingBag,
  FaSync,
  FaExclamationTriangle,
} from "react-icons/fa";
import { useOneOrder } from "model/queryCalls/queryCalls";
import { useUpdateOrder } from "model/queryCalls/orders";

const OrderDetails: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { data, isLoading, isError } = useOneOrder({ orderId });
  const { mutate: updateOrder, isLoading: isUpdating } = useUpdateOrder();
  const order = data?.data[0];
  const [orderStatus, setOrderStatus] = React.useState(order?.status);

  if (isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <div className="rounded-xl bg-white p-6 text-center shadow-lg">
          <FaSync className="mx-auto mb-3 h-12 w-12 animate-spin text-indigo-500" />
          <h2 className="text-xl font-semibold text-gray-700">
            Loading Order Details
          </h2>
          <p className="text-sm text-gray-500">
            Gathering your order information...
          </p>
        </div>
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-red-50">
        <div className="rounded-xl bg-white p-6 text-center shadow-lg">
          <FaExclamationTriangle className="mx-auto mb-3 h-12 w-12 text-red-500" />
          <h2 className="text-xl font-semibold text-red-700">
            Order Not Found
          </h2>
          <p className="text-sm ">Unable to retrieve order details</p>
        </div>
      </div>
    );
  }

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrderStatus(e.target.value);
  };

  const handleUpdateClick = () => {
    if (!orderStatus) {
      return;
    }
    updateOrder(
      { orderId, orderStatus },
      {
        onSuccess: () => {
          order.status = orderStatus;
        },
      }
    );
  };

  const isCompleted = order.status === "completed";

  return (
    <div className="container mx-auto flex w-full flex-col gap-5 bg-white px-4 py-8">
      {/* Order Header */}
      <div className="mb-6 rounded-xl bg-white shadow-md">
        <div className="rounded-t-xl bg-gray-200 p-4">
          <div className="flex items-center">
            <FaBox className="mr-3 h-6 w-6 text-gray-700" />
            <h1 className="text-lg font-bold text-gray-700">
              Order #{order.refNumber}
            </h1>
          </div>
        </div>

        {/* Order Summary Grid */}
        <div className="grid gap-4 p-4 md:grid-cols-2">
          {/* Left Column */}
          <div className="space-y-3">
            <div className="flex items-center justify-between border-b pb-2">
              <span className="text-sm ">Total Amount</span>
              <span className="text-lg font-bold text-indigo-600">
                {order.amount.toFixed(2)}
              </span>
            </div>
            <div className="flex items-center justify-between border-b pb-2">
              <span className="text-sm ">Order Status</span>
              <span
                className={`rounded-full px-3 py-1 text-xs font-medium ${
                  order.status === "completed"
                    ? "bg-green-100 text-green-700"
                    : "bg-yellow-100 text-yellow-700"
                }`}
              >
                {order.status}
              </span>
            </div>
            <div className="flex items-center justify-between border-b pb-2">
              <span className="text-sm ">Order Type</span>
              <span className="rounded-full px-3 py-1  font-medium">
                {order.orderType}
              </span>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-3">
            <div className="flex items-center justify-between border-b pb-2">
              <span className="text-sm ">Payment Status</span>
              <span
                className={`rounded-full px-3 py-1 text-xs font-medium ${
                  order.paymentStatus === "paid"
                    ? "bg-green-100 text-green-700"
                    : "bg-red-100 text-red-700"
                }`}
              >
                {order.paymentStatus}
              </span>
            </div>
            <div className="flex items-center justify-between border-b pb-2">
              <span className="text-sm ">Placed At</span>
              <span className="text-sm text-gray-800">
                {new Date(order.metadata.createdAt).toLocaleString()}
              </span>
            </div>
            <div>
              {order.orderType === "dine_in" && (
                <div className="flex items-center justify-between border-b pb-2">
                  <p className="text-sm ">Dine-in Details</p>
                  <ul>
                    <li>Date: {order.dineInDetails.date}</li>
                    <li>Time: {order.dineInDetails.time}</li>
                    <li>
                      Number of Guests: {order.dineInDetails.numberOfGuests}
                    </li>
                  </ul>
                </div>
              )}
              {order.orderType === "delivery" && (
                <div className="flex items-center justify-between border-b pb-2">
                  <p className="text-sm ">Delivery Address</p>

                  <p className="text-sm">
                    {order.address.address ||
                      " No details provided for Delivery."}
                  </p>
                </div>
              )}
              {order.orderType === "pickup" && (
                <div className="flex items-center justify-between border-b pb-2">
                  <p className="text-sm ">Pickup</p>
                  <p className="text-sm">
                    No additional details provided for pickup.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        {/* Restaurant Details */}
        <div className="rounded-xl bg-white shadow-md">
          <div className="rounded-t-xl bg-gray-200 p-3">
            {" "}
            {/* Light gray background */}
            <div className="flex items-center">
              <FaMapMarkerAlt className="mr-2 h-5 w-5 text-gray-700" />
              <h2 className="text-base font-bold text-gray-700">
                Restaurant Details
              </h2>
            </div>
          </div>
          <div className="space-y-3 p-4">
            <div className="border-b pb-2">
              <p className="text-xs ">Restaurant Name</p>
              <p className="text-sm font-semibold">
                {order.restaurantDetails.name.en}
              </p>
            </div>
            <div className="border-b pb-2">
              <p className="text-xs ">Address</p>
              <p className="text-sm font-semibold">
                {order.branchDetails.address.address}
              </p>
            </div>
            <div>
              <p className="text-xs ">Contact</p>
              <p className="text-sm font-semibold">
                {order.branchDetails.contactDetails}
              </p>
            </div>
          </div>
        </div>

        {/* Ordered Items */}
        <div className="rounded-xl bg-white shadow-md">
          <div className="rounded-t-xl bg-gray-200 p-3">
            {" "}
            {/* Light gray background */}
            <div className="flex items-center">
              <FaShoppingBag className="mr-2 h-5 w-5 text-gray-700" />
              <h2 className="text-base font-bold text-gray-700">
                Ordered Items
              </h2>
            </div>
          </div>
          <div className="space-y-3 p-4">
            {order.items.map((item: any, index: number) => (
              <div
                key={index}
                className="flex items-center justify-between border-b pb-3 last:border-b-0"
              >
                <div>
                  <p className="text-sm font-semibold">{item.name}</p>
                  <p className="text-xs text-gray-500">
                    {item.modifiers.length > 0
                      ? item.modifiers[0].name
                      : "No modifiers"}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-base font-bold text-indigo-600">
                    {item.price.toFixed(2)}
                  </p>
                  <p className="text-xs text-gray-500">Qty: {item.quantity}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Status Update */}
      <div className="mt-6 rounded-xl bg-white shadow-md">
        <div className="rounded-t-xl bg-gray-200 p-3">
          <div className="flex items-center">
            <FaSync className="mr-2 h-5 w-5 text-gray-700" />
            <h2 className="text-base font-bold text-gray-700">
              Update Order Status
            </h2>
          </div>
        </div>
        <div className="p-4">
          <div className="flex space-x-4">
            <select
              value={orderStatus}
              onChange={handleStatusChange}
              disabled={isUpdating || isCompleted}
              className="flex-1 rounded-lg border border-gray-300 px-3 py-2 text-sm text-gray-700 focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="placed">Placed</option>
              <option value="approved">Approved</option>
              <option value="completed"> Completed</option>
            </select>
            <button
              onClick={handleUpdateClick}
              disabled={isUpdating || isCompleted || !orderStatus}
              className="w-32 rounded-lg bg-gradient-to-r from-indigo-600 to-purple-600 px-3 py-2 text-sm text-white transition duration-300 hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            >
              {isUpdating ? "Updating..." : "Update Status"}
            </button>
          </div>
          {isCompleted && (
            <p className="mt-2 text-sm text-gray-500">
              This order is completed. No further updates can be made.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
