import { useContext } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { UserContext } from "controller/context/userContext";


const Modifiers = () => {
  const { userType } = useContext(UserContext);

  const navigate = useNavigate();

  const handleAddModifier = () => {
    navigate(`/${userType}/add-modifier`);
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <div className="mb-10 flex justify-end lg:mb-0">
          <div className="relative mb-2 w-32">
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleAddModifier}
            >
              Add Modifier
            </button>
          </div>
        </div>

        <Card extra={"w-full h-full p-3"}>
        </Card>
      </div>
    </div>
  );
};

export default Modifiers;
